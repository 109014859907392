import React from 'react'
import { Button, Container, Text } from 'theme-ui'

import Seo from '../components/shared/seo'
import Layout from '../components/shared/layout'
import Hero from '../components/shared/hero'
import InternalLink from '../components/shared/internal-link'

class ArtistsIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="404 Page not found" />
        <Container sx={{minHeight: "70vh"}}>

        <Hero title={'404'} />
        <Text as="p">
          The page could not be found.
        </Text>
        <InternalLink to="/">
          <Button my={4} variant="primary">
            Return
          </Button>
        </InternalLink>
        </Container>
      </Layout>
    )
  }
}

export default ArtistsIndex
